import { Pipe, PipeTransform } from "@angular/core";
import { AdminEngineService } from "../services/admin-engine.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { EnvService } from "src/services/env.service";

@Pipe({
  standalone: true,
  name: "messageParagraphs",
})
export class MessageParagraphsPipe implements PipeTransform {
  private static data: { [key: string]: string } = {};

  constructor(
    private adminEngineService: AdminEngineService,
    private sanitizer: DomSanitizer
  ) {}

  initialize() {
    return this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "message-paragraphs")
      .then((r: { [key: string]: string }) => (MessageParagraphsPipe.data = r));
  }

  transform(value: string): SafeHtml {
    const paragraphs = MessageParagraphsPipe.data[value] || "";
    const html = Array.isArray(paragraphs)
      ? paragraphs.length <= 1
        ? paragraphs[0] || ""
        : paragraphs.map((p) => `<p>${p}</p>`).join("\n")
      : paragraphs;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
