import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
} from "@angular/core";

import { StudentService } from "../../services/student.service";
import { ErrorService } from "../../services/error.service";
import { AuthService } from "../../services/auth.service";
import { EnvService } from "../../services/env.service";
import { LoadingService } from "../../services/loading.service";
import { Student, StudentFactory } from "../../models/student-transform.model";
import { ApiService } from "../../services/api.service";
import { FormsModule } from "@angular/forms";

const console: Console = window.console;

@Component({
  standalone: true,
  selector: "advisor-component",
  templateUrl: "advisor.component.html",
  styleUrls: ["advisor.component.scss"],
  imports: [NgxSkeletonLoaderModule, FormsModule],
})
export class AdvisorComponent implements OnInit {
  diagMode = false;

  displayPage = false;
  displayMessage = "Loading Form...";
  message: string[] = [];
  query = "";
  importData = "";
  auths: string[];

  studentValue: Student | undefined;

  @Output() studentChange = new EventEmitter<Student | undefined>();

  @Input()
  get student() {
    return this.studentValue;
  }

  set student(val) {
    this.studentValue = val;
    this.studentChange.emit(this.studentValue);
  }

  constructor(
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private studentService: StudentService,
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.displayPage = false;
    this.student = undefined;
    this.loadingService.start("Getting Advisor Permissions");
    this.authService.User.then(() => this.canViewAs())
      .then((viewAs: boolean) => {
        this.displayPage = viewAs;
        if (!this.displayPage) {
          this.displayMessage =
            "You do not have proper permission to access the resources on this page.";
        }
        this.loadingService.stop("Getting Advisor Permissions");
      })
      .catch((err: any) => {
        this.errorService.logError(err);
        this.displayMessage = "Loading Form: Failed -- " + err.message;
        this.errorService.showLoadingError(err.message);
        this.loadingService.stopAll();
        throw err;
      });
  }

  getStudent() {
    this.message = [];
    this.student = undefined;
    if (!this.query) {
      this.message.push("Please input either Campus ID or Panther Number");
    } else {
      this.loadingService.start("Searching for Student");
      this.studentService
        .getStudent(this.query)
        .then((student: any) => {
          if (
            Object.keys(student.studentId).length === 0 &&
            student.studentId.constructor === Object
          ) {
            this.message.push("Campus ID or Panther Number does not exist");
          } else {
            this.student = student;
          }
          this.query = "";
        })
        .catch((err: any) => {
          this.errorService.logError(err);
          this.message.push("Failed to load student");
        })
        .then(() => this.loadingService.stop("Searching for Student"));
    }
  }

  public async getAuthorizations(): Promise<string[]> {
    if (typeof this.auths === "undefined") {
      const auths: any = await this.apiService.getAuth(
        ApiService.joinUrl(EnvService.BANNER_URL, "roleAuthorizations")
      );
      this.auths = "authList" in auths ? auths.authList : (auths as any);
    }
    return this.auths;
  }

  public async canViewAs(): Promise<boolean> {
    const auths = await this.getAuthorizations();
    return auths && auths.indexOf("viewAs") >= 0;
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "i" && event.ctrlKey) {
      this.diagMode = true;
    }
  }

  importStudent() {
    this.student = JSON.parse(this.importData);
  }

  importBanner() {
    this.student = StudentFactory(JSON.parse(this.importData));
  }

  exportBanner() {
    this.sendToClipboard(JSON.stringify(this.studentService.bannerStudent));
  }

  exportStudent() {
    this.sendToClipboard(JSON.stringify(this.studentService.student));
  }

  sendToClipboard(data: string) {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData && e.clipboardData.setData("text/plain", data);
      e.preventDefault();
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }
}
