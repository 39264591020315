import { MatButtonModule } from '@angular/material/button';
import { PortalHeaderComponent } from "./../components/header/header.component";
import { TestEnvironmentComponent } from "./../components/test-environment/test-environment.component";
import { QuickLinksComponent } from "./../components/quick-links/quick-links.component";
import { DashboardComponent } from "./../components/dashboard/dashboard.component";
import { AdvisorComponent } from "./../components/advisor/advisor.component";
import { ChecklistComponent } from "./../components/checklist/checklist.component";
import { BannerComponent } from "./../components/banner/banner.component";
import { CrossPlatformMessagesComponent } from "./../components/cross-platform-messages/cross-platform-messages.component";
import { MatMenuModule } from "@angular/material/menu";
import { DowntimeComponent } from "./../components/downtime/downtime.component";
import { NoPersonaComponent } from "./../components/no-persona/no-persona.component";
import { LoginComponent } from "./../components/login/login.component";
import { Component, ChangeDetectorRef, NgModule } from "@angular/core";
import { User } from "../services/auth.service";
import { ErrorService } from "../services/error.service";
import { AuthService } from "../services/auth.service";
import { AdminEngineService } from "../services/admin-engine.service";
import { Student, StudentFactory } from "../models/student-transform.model";
import { StudentService } from "../services/student.service";
import { LoadingService } from "../services/loading.service";
import { BannerData } from "../models/banner-data.model";
import { StudentResourceService } from "../services/student-resource.service";
import { RenderedComponentsService } from "../services/rendered-components.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectRoleComponent } from "../components/select-role/select-role.component";
import { ApiService } from "src/services/api.service";
import { EnvService } from "src/services/env.service";
import { InitializeService } from "src/services/initialize.service";
import { MatIcon } from "@angular/material/icon";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MessageParagraphsPipe } from 'src/pipes/messageParagraphs.pipe';
import { ContexturlPipe } from 'src/pipes/contexturl.pipe';
@Component({
  standalone: true,
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  imports: [
    TestEnvironmentComponent,
    PortalHeaderComponent,
    LoginComponent,
    NoPersonaComponent,
    DowntimeComponent,
    MessageParagraphsPipe,
    ContexturlPipe,
    MatMenuModule,
    MatButtonModule,
    MatIcon,
    CrossPlatformMessagesComponent,
    BannerComponent,
    ChecklistComponent,
    AdvisorComponent,
    DashboardComponent,
    NgxSkeletonLoaderModule,
    QuickLinksComponent,
  ],
})
export class AppComponent {
  errorMessage: string;
  countdown: number;
  userData: any;
  displayChecklist: boolean;
  partialUserData: any;
  user: User;
  onlyStudent: boolean = true;
  persona: { label: string; code: string };
  advisorView = false;
  isDown = false;
  downtimeChecked = false;
  authFailed = false;
  student: Student;
  isLoading = true;
  preferredName = "";
  preferredFirstName = "";
  userName = "";
  personas = [];
  userPreferences: any;
  defaultRole: string;
  isOffset: boolean = false;

  constructor(
    public authService: AuthService,
    private errorService: ErrorService,
    private apiService: ApiService,
    private adminEngineService: AdminEngineService,
    private studentService: StudentService,
    private studentResourceService: StudentResourceService,
    private initializeService: InitializeService,
    private renderedComponents: RenderedComponentsService,
    public loadingService: LoadingService,
    private messageParagraphPipe: MessageParagraphsPipe,
    private contextUrlPipe: ContexturlPipe,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    // Debug Logging / Debug Access
    // (<any> window).debugLoginService = loginService;
    (<any>window).debugStudentService = studentService;
    (<any>window).debugLoadStudent = (data: Student) => this.loadStudent(data);
    (<any>window).debugLoadBannerStudent = (data: BannerData) =>
      this.loadBannerStudent(data);

    // Begin loading process.
    this.loadingService.loadingChanges.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
    this.loadingService.start("Initializing");

    const resourceCalls = [
      this.checkOffset(),
      this.authService
        .getUser()
        .then((user: User) => {
          StudentService.user = user;
          this.user = user;
        })
        .then(() => this.checkDownTime())
        .catch((e) => {
          console.log(e);
          this.loadingService.stop("Initializing");
          this.authFailed = true;
        }),
      this.studentResourceService.initialize(),
      this.renderedComponents.initialize(),
      this.messageParagraphPipe.initialize(),
      this.contextUrlPipe.initialize(),
      this.initializeService.initialize(),
    ];
    Promise.all(resourceCalls)
      .then(() => {
        this.loadingService.stop("Initializing");
        this.displayChecklist =
          this.renderedComponents.getComponent("checklist");
      })
      .catch((e) => {
        this.loadingService.stop("Initializing");
        console.log(e);
        this.errorMessage =
          "The application encountered a fatal error while trying to load. Please manually reload the browser page or it will attempt to reload in ";
        this.countdown = 25;
        setInterval(() => this.countdown--, 1000);
        setTimeout(() => window.location.reload(), 25000);
      });
  }

  async setDefaultRole(persona) {
    this.defaultRole = persona.label;
    this.apiService.postAuth(
      EnvService.GSU_DATA_SERVICE_URL + "/user_preferences",
      { "Content-Type": "application/json" },
      {
        pantherId: this.user.profile.gsupersonpantherid,
        defaultRole: persona.label,
      }
    );
  }

  async checkOffset() {
    const offset = await this.apiService.get<{ timestampMS: number }>(
      EnvService.UTC_URL
    );
    const offsetSeconds = Math.abs((offset.timestampMS - Date.now()) / 1000);
    this.isOffset = offsetSeconds >= 290;
  }

  async checkDownTime() {
    const info: { isDown: boolean; allow: string[] } =
      await this.adminEngineService.getKey(
        EnvService.DASHBOARD_APP_ID,
        "downtime"
      );
    if (info.isDown) {
      await this.authService.User;
      info.isDown = !(
        info.allow?.indexOf(this.authService.userSnapshot.pantherId) >= 0
      );
    }
    this.isDown = info.isDown;
    this.downtimeChecked = true;
    this.ref.detectChanges();

    if (this.isDown) {
      setTimeout(() => this.checkDownTime(), 30000);
    }

    if (this.advisorView || !this.isDown) {
      return this.authService.User.then(async (user: User) => {
        await this.fetchPersonas();
        this.selectPersona();
      }).catch((err) => this.errorService.logError(err));
    }
  }

  async fetchPersonas(): Promise<void> {
    return this.apiService
      .getAuth(EnvService.BANNER_URL + "/personas", {})
      .then((response) =>
        this.apiService
          .getAuth(EnvService.GSU_DATA_SERVICE_URL + "/user_preferences", {})
          .then((preferences) => {
            this.userPreferences = preferences;
            const r = JSON.parse(JSON.stringify(<any>response));
            this.defaultRole = this.userPreferences.defaultRole;
            this.preferredName = r.preferredName;
            this.preferredFirstName = r.preferredFirstName;
            this.personas = r.roles;
            if (
              this.personas?.length < 2 &&
              this.personas.find((i) => i.label.trim() === "Student")
            ) {
              this.onlyStudent = true;
              this.setPersona(this.personas[0]);
            } else this.onlyStudent = false;
          })
      );
  }

  async selectPersona() {
    if (
      this.userPreferences.defaultRole &&
      this.personas.find((i) => i.label === this.userPreferences.defaultRole)
    ) {
      this.setPersona(
        this.personas.find((i) => i.label === this.userPreferences.defaultRole)
      );
    } else if (this.personas.length === 1) {
      this.setPersona(this.personas[0]);
    } else if (this.personas.length !== 0) {
      const personaDlg = this.dialog.open(SelectRoleComponent, {
        disableClose: true,
      });
      const data = {
        personas: this.personas,
        dlg: personaDlg,
        persona: undefined,
      };
      personaDlg.componentInstance["data"] = data;
      personaDlg
        .afterClosed()
        .toPromise()
        .then(() => {
          this.setDefaultRole(data.persona);
          this.setPersona(data.persona);
        });
    }
  }

  // TODO: determine logic here.
  setPersona(persona) {
    this.student = void 0;
    this.persona = persona;
    this.advisorView = this.persona.label.includes("Advisor") ? true : false;
    this.partialUserData = { persona, ...this.user };
    this.ref.detectChanges();
    if (!this.advisorView) {
      this.loadingService.start("Loading Student Information");
      this.studentService
        .getStudent()
        .then((student: Student) => this.loadStudent(student))
        .catch((err) => this.errorService.logError(err))
        .then(() =>
          this.apiService.getAuth(
            `${EnvService.GSU_DATA_SERVICE_URL}/paws/targetedLists/${this.user.profile.gsupersonpantherid}`,
            {}
          )
        )
        .then(
          (targetedLists) =>
          (this.userData = {
            persona,
            ...this.student,
            ...this.user,
            ...this.userPreferences,
            targetedLists,
          })
        )
        .catch((err) => console.log(err))
        .then(() => this.loadingService.stop("Loading Student Information"));
    }
  }

  loadStudent(student: Student) {
    this.student = student;
    this.userName = `${this.student.studentPreferredFirstName} ${this.student.studentLastName}`;
    this.ref.detectChanges();
  }

  loadBannerStudent(student: BannerData) {
    this.student = StudentFactory(student);
    this.userName = `${this.student.studentPreferredFirstName} ${this.student.studentLastName}`;
  }
}
