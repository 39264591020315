import { MatListModule } from "@angular/material/list";
import { SafePipe } from "./../../pipes/safe.pipe";
import { MatExpansionModule } from "@angular/material/expansion";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Component, Input, OnChanges } from "@angular/core";
import { AdminEngineService } from "../../services/admin-engine.service";
import { ApiService } from "../../services/api.service";
import { EnvService } from "../../services/env.service";
import { visible } from "../../services/helpers";

@Component({
  standalone: true,
  selector: "quick-links",
  templateUrl: "./quick-links.component.html",
  styleUrls: ["./quick-links.component.scss"],
  imports: [
    NgxSkeletonLoaderModule,
    MatExpansionModule,
    SafePipe,
    MatListModule,
  ],
})
export class QuickLinksComponent implements OnChanges {
  @Input() data;
  items: any[];
  params: any[];
  displayParams: any = {};
  panelOpenState = false;

  constructor(
    private apiService: ApiService,
    private adminEngineService: AdminEngineService
  ) {}

  ngOnChanges(): void {
    console.log("checking ql");
    Promise.all([
      this.apiService
        .postAuth(
          EnvService.BANNER_URL + "/quickLinks",
          {},
          { role: this.data.persona.code }
        )
        .then(
          async (response) =>
            (this.items = JSON.parse((<any>response).quickLinks))
        )
        .catch((e) => (this.items = [])),
      this.adminEngineService
        .getKey(EnvService.DASHBOARD_APP_ID, "quick-links")
        .then((r) => {
          this.params = <any>r;
          this.displayParams = this.params.find((i) => visible(this.data, i));
        })
        .catch((e) => {
          this.params = void 0;
          console.log(e);
        }),
    ]);
  }

  display(data, item) {
    return visible(data, item);
  }
}
