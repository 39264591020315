import { MatButtonModule } from '@angular/material/button';
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../environments/environment";
import { MessageParagraphsPipe } from "../../pipes/messageParagraphs.pipe";

@Component({
  standalone: true,
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  imports: [ MatButtonModule, MessageParagraphsPipe],
})
export class LoginComponent implements OnInit, OnChanges {
  @Input() isOffset: boolean = false;
  env = environment;
  redirect = false;
  prompt = false;
  href: string;

  constructor(public authService: AuthService) {}

  ngOnChanges() {
    try {
      this.login();
    } catch {}
  }

  ngOnInit(): void {
    this.prompt = environment.loginConfig.prompt;
    if (environment.loginConfig.popout) {
      console.log("this is a popout env");
      this.href = environment.loginConfig.popout;
      this.redirect = false;
    } else if (
      environment.loginConfig.redirect &&
      environment.loginConfig.prompt
    ) {
      this.redirect = true;
      this.href = `${
        environment.loginConfig.redirect
      }&return=${encodeURIComponent(window.location.href)}`;
    } else {
      window.location.href = `${
        environment.loginConfig.redirect
      }&return=${encodeURIComponent(window.location.href)}`;
    }
  }

  login() {
    console.log("attempting to log in");
    return this.authService.getUser();
  }
}
