@if((authService.showLogin && !authFailed) || isOffset) {
<app-login [isOffset]="isOffset" class="animated fadeIn"></app-login>
<!-- currently will not display offset if "logged in" -->
}

@if (user) {
<header [user]="user" [preferredName]="preferredName" class="animated fadeIn"></header>
}

@if (preferredName !== '' && personas.length === 0) {
<no-persona-component class="animated fadeIn"></no-persona-component>
}

@if (isDown) {
<downtime-component class="animated fadeIn"></downtime-component>
}

@if(isLoading && !user || !persona) {
<div class="loading">
  <h1> {{ loadingService.message }} </h1>
</div>
}

@if (!isDown && !!user && persona && downtimeChecked) {
<div class="view-as">
  <span class="indicator">Currently viewing this page as </span>

  @if (onlyStudent) {
  <span>{{ persona.label }}</span>
  }@else {
  <span>
    <button mat-button color="primary" [matMenuTriggerFor]="menu" class="personaSelect">
      <span class="personaLabel">{{ persona.label }}</span>

      @if (defaultRole === persona.label) {
      <span class="personaLabel"> (default role)</span>
      }
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    @if (defaultRole !== persona.label) {
    <button (click)="setDefaultRole(persona)" class="btn small default-button">
      Make default
    </button>
    }
    <span class="indicator">(Click on the down arrow to switch roles)</span>
  </span>
  }

  <mat-menu #menu="matMenu">
    @for (persona of personas; track $index) {
    <button (click)="setPersona(persona)" mat-menu-item>
      {{ persona.label }}
    </button>
    }
  </mat-menu>
</div>


@if (persona) {
<cross-platform-messages class="animated fadeIn" [user]="user" [student]="student" [userPreferences]="userPreferences"
  [personas]="personas" [persona]="persona" [targetedLists]="userData?.targetedLists">
</cross-platform-messages>
}

<div class="app-body">

  @if ((persona.label === 'Student') && preferredFirstName) {
  <banner [student]="student" class="animated fadeIn" [preferredName]="preferredFirstName"></banner>
  }

  @if (userData && persona.label === 'Student' && displayChecklist) {
  <checklist [userData]="userData" class="animated fadeIn"></checklist>
  }

  @if (persona.label !== 'Faculty') {
  <div class="body-content">
    <div class="app-content">

      @if (advisorView) {
      <advisor-component [(student)]="student"></advisor-component>
      }

      @if (student) {
      <dashboard-component [student]="student"></dashboard-component>
      }

      @if (isLoading) {
      <div class="mock-dashboard animated fadeIn">
        <div>
          <h2>My Registration</h2>
          <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 800 }" class="animated fadeIn"></ngx-skeleton-loader>
        </div>
        <div>
          <h2>My Bill</h2>
          <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 800 }" class="animated fadeIn"></ngx-skeleton-loader>
        </div>
        <div>
          <h2>My Financial Aid</h2>
          <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 800 }" class="animated fadeIn"></ngx-skeleton-loader>
        </div>
      </div>
      }

    </div>
  </div>
  }

  <quick-links class="animated fadeIn" [data]="partialUserData"></quick-links>
</div>
}

<!-- Ideally, we should combine the diagnostics page with this application. -->
@if (errorMessage) {
<div class="error-overlay">
  <h3 style="color: #fff !important;">{{errorMessage}}{{countdown}} seconds.</h3>
  <img class="logo" src="https://cdn.gsu.edu/img/gsuLogo.svg" alt="" />
</div>
}

<app-test-environment></app-test-environment>