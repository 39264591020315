import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  get<T>(url: string, headers: { [key: string]: string } = {}): Promise<T> {
    return <Promise<T>>this.http.get(url, { headers }).toPromise();
  }

  async getAuth<T>(
    url: string,
    headers: { [key: string]: string } = {}
  ): Promise<T> {
    const loginHeaders = await this.authService.getHeaders();
    const r = await (<Promise<T>>(
      this.http
        .get(url, { headers: { ...headers, ...loginHeaders } })
        .toPromise()
    ));
    return r;
  }

  put<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    return <Promise<T>>this.http.put(url, body, { headers }).toPromise();
  }

  async putAuth<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    const loginHeaders = await this.authService.getHeaders();
    return <Promise<T>>(
      this.http
        .put(url, body, { headers: { ...headers, ...loginHeaders } })
        .toPromise()
    );
  }

  post<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    return <Promise<T>>this.http.post(url, body, { headers }).toPromise();
  }

  async postAuth<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    const loginHeaders = await this.authService.getHeaders();
    return <Promise<T>>(
      this.http
        .post(url, body, { headers: { ...headers, ...loginHeaders } })
        .toPromise()
    );
  }

  delete<T>(url: string, headers: { [key: string]: string } = {}): Promise<T> {
    return <Promise<T>>this.http.delete(url, { headers }).toPromise();
  }

  async deleteAuth<T>(
    url: string,
    headers: { [key: string]: string } = {}
  ): Promise<T> {
    const loginHeaders = await this.authService.getHeaders();
    return <Promise<T>>(
      this.http
        .delete(url, { headers: { ...headers, ...loginHeaders } })
        .toPromise()
    );
  }

  static joinUrl(...paths: string[]) {
    let url = paths[0];
    for (let i = 1; i < paths.length; i++) {
      const path = paths[i];
      if (url.substring(-1) !== "/") {
        url += "/";
      }

      if (path.indexOf("/") === 0) {
        url += path.substring(1);
      } else {
        url += path;
      }
    }
    return url;
  }
}
