@if (!student) {
<div>
  <ngx-skeleton-loader count="1" [theme]="{ 'height.px': 135, 'margin': -32 }"></ngx-skeleton-loader>
</div>
}@else {
<div class="banner-wrapper">
  <div class="animated fadeIn">
    <h1>{{welcomeString}}</h1>

    @if (student.cachedAt) {
    <span class="cachedAt">
      My Georgia State University student status was last updated
      <strong>
        {{ student.cachedAt | date: 'medium' }}
        <a (click)="refreshStudentData()">Refresh</a>
      </strong>
    </span>
    }

  </div>
  <a class="child animated fadeIn" [href]="knowledgeBaseUri" target="_blank">
    <img src="assets/images/panther_answer.svg" />
    <div class="pantherText">
      <div>Have Questions?</div>
      <div class="gray">Search PANTHER ANSWER</div>
    </div>
  </a>
</div>
}