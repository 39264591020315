import { MatIconModule } from "@angular/material/icon";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { EnvService } from "../../services/env.service";
import { AdminEngineService } from "../../services/admin-engine.service";
import { SafePipe } from "../../pipes/safe.pipe";

@Component({
  standalone: true,
  selector: "downtime-component",
  templateUrl: "downtime.component.html",
  styleUrls: ["downtime.component.scss"],
  imports: [SafePipe, MatIconModule],
})
export class DowntimeComponent implements OnInit {
  public configDowntimeInfo: any;
  constructor(
    private adminEngineService: AdminEngineService,
    public authService: AuthService
  ) {}
  async ngOnInit() {
    this.configDowntimeInfo = await this.adminEngineService.getKey(
      EnvService.DASHBOARD_APP_ID,
      "downtime"
    );
  }
}
