import { SafePipe } from "../../pipes/safe.pipe";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AdminEngineService } from "../../services/admin-engine.service";
import { ApiService } from "../../services/api.service";
import { EnvService } from "../../services/env.service";
import { display } from "../../services/helpers";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "checklist",
  templateUrl: "checklist.component.html",
  styleUrls: ["checklist.component.scss"],
  imports: [MatProgressBarModule, SafePipe, CommonModule],
})
export class ChecklistComponent implements OnChanges {
  @Input() userData: any;
  checklistConfiguration: { [key: string]: any };
  selectedChecklist: any;
  checklists: any;
  visibleChecklists: any[];
  visibleItems: any[];
  headers: any;
  loading = false;
  checkListProgress = 0;
  background: string;
  displayChecklist: boolean = false;

  constructor(
    private apiService: ApiService,
    private adminEngineService: AdminEngineService
  ) {}

  get progress() {
    let total = 0;
    let completed = 0;

    if (!this.visibleChecklists) return 0;

    if (!this.userData) return 0;

    for (const checklist of this.visibleChecklists) {
      for (const item of checklist.items) {
        if (display(this.userData, item)) {
          total += 1;
          if (item.completed) completed += 1;
        }
      }
    }

    if (completed !== 0 && total !== 0)
      return Math.round((completed / total) * 100);
    return 0;
  }

  async ngOnChanges() {
    if (!this.checklistConfiguration) {
      this.checklistConfiguration = await this.adminEngineService.getKey(
        EnvService.DASHBOARD_APP_ID,
        "checklists"
      );
      this.checklists = this.checklistConfiguration.checklists;
    }

    if (!this.userData || !this.userData.enrollmentAdvice) return;

    if (!this.checklists) return;

    if (this.userData.checklistStatuses) {
      this.checklists.forEach((checklist) =>
        checklist.items.forEach((item) => {
          if (
            this.userData.checklistStatuses.find(
              (i) => i.id === item.id && i.complete === true
            )
          )
            item.completed = true;
        })
      );
    }

    this.visibleChecklists = this.checklists.filter((c) =>
      display(this.userData, c)
    );
    this.headers = this.checklistConfiguration.header;
    this.selectChecklist(this.visibleChecklists[0]);

    this.displayChecklist = display(this.userData, this.checklistConfiguration);
  }

  selectChecklist(checklist) {
    if (!checklist) return;

    this.selectedChecklist = checklist;
    this.visibleItems = this.selectedChecklist.items.filter((i) =>
      display(this.userData, i)
    );
    console.log("visibleItems", this.visibleItems);
  }

  toggleItem(item) {
    if (item.completed) {
      item.completed = false;
    } else {
      item.completed = true;
    }
    const checklistStatuses = [];
    this.visibleChecklists.forEach((checklist) =>
      checklist.items.forEach((i) => {
        if (display(this.userData, i))
          checklistStatuses.push({ id: i.id, complete: !!i.completed });
      })
    );
    this.apiService.postAuth(
      EnvService.GSU_DATA_SERVICE_URL + "/user_preferences",
      { "Content-Type": "application/json" },
      {
        pantherId: this.userData.studentId,
        checklistStatuses,
      }
    );
  }

  nextChecklist() {
    let selection;
    this.visibleChecklists.forEach((c, i) => {
      if (
        c.title === this.selectedChecklist.title &&
        this.visibleChecklists[i + 1]
      )
        selection = this.visibleChecklists[i + 1];
    });
    this.selectChecklist(selection);
  }

  previousChecklist() {
    let selection;
    this.visibleChecklists.forEach((c, i) => {
      if (
        c.title === this.selectedChecklist.title &&
        this.visibleChecklists[i - 1]
      )
        selection = this.visibleChecklists[i - 1];
    });
    this.selectChecklist(selection);
  }
}
