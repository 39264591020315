import { MessageParagraphsPipe } from "./../../pipes/messageParagraphs.pipe";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  standalone: true,
  selector: "no-persona-component",
  templateUrl: "./no-persona.component.html",
  styleUrls: ["./no-persona.component.scss"],
  imports: [MessageParagraphsPipe],
})
export class NoPersonaComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
