import { Injectable } from '@angular/core';
import { EnvService } from './env.service';
import { StudentFactory, Student } from '../models/student-transform.model';
import { ApiService } from './api.service';
import { ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  student: Student;
  static user;
  bannerStudent: any;
  context: ReplaySubject<{ [key: string]: string | number }> = new ReplaySubject<{ [key: string]: string | number }>(1);

  constructor(
    private apiService: ApiService
  ) { }

  async getStudent(query?: string): Promise<Student> {
    let student: Student;
    if (query) {
      student = await this.loadOtherStudent(query);
    } else if (this.student) {
      if (this.student.studentId !== StudentService.user.profile.gsupersonpantherid) {
        student = await this.loadLoggedInStudent();
      } else {
        student = this.student;
      }
    } else {
      student = await this.loadLoggedInStudent();
    }
    return student;
  }

  public loadLoggedInStudent(refresh?: boolean): Promise<Student> {
    return this.loadStudent(ApiService.joinUrl(EnvService.BANNER_URL, 'bannerData' + (refresh ? '?refresh=1' : '?t=' + Date.now())));
  }

  private loadOtherStudent(queryStr: string): Promise<Student> {
    const queryObj: any = this.translateQuery(queryStr);
    switch (queryObj.field) {
      case 'CampusID':
        if (this.student && queryObj.value === this.student.studentCampusId) {
          return Promise.resolve(this.student);
        }
        break;
      case 'Panther #':
        if (this.student && queryObj.value === this.student.studentId) {
          return Promise.resolve(this.student);
        }
        break;
      default:
        return Promise.reject('Unknown field "' + queryObj.field + '" with value "' + queryObj.value + '" after translating query string "' + queryStr + '"');
    }
    const baseurl: string = EnvService.BANNER_URL;
    const path: string = (baseurl.slice(-1) === '/' ? '' : '/') + 'advisorView';
    const body: string = queryObj;
    return this.loadStudent(ApiService.joinUrl(baseurl, path), body);
  }

  private loadStudent(baseurl: string, body?: string | undefined): Promise<Student> {
    const req: Promise<any> = body ? this.apiService.postAuth(baseurl, {}, body) : this.apiService.getAuth(baseurl, {});
    return req
      .then((obj: any): Student => {
        this.bannerStudent = obj;
        this.student = StudentFactory(obj);
        this.context.next({
          PantherId: this.student.studentId,
          CampusId: this.student.studentCampusId
        });
        return this.student;
      });
  }

  async updateContext(updates: { [key: string]: string | number }) {
    let sub: Subscription;
    const c: { [key: string]: string | number } =
      await new Promise((resolve) => {
        sub = this.context.subscribe(v => resolve(v));
      });
    sub.unsubscribe();
    this.context.next({ ...c, ...updates });
  }

  private translateQuery(queryString: string) {
    const campusid = queryString.match(/^((\s|[.-])*)([a-zA-Z]*)((\s|[.-])*)([a-zA-Z]+)((\s|[.-])*)([0-9]*)((\s|[.-])*)$/);
    if (campusid) {
      return { field: 'CampusID', value: campusid[3] + campusid[6] + campusid[9] };
    } else {
      // Panther #: 9 digits, possibly interspersed with whitspace and/or punctuation; remove non-digit characters
      const panthernum = queryString.match(/^((\s|[.-])*)([0-9]{3})((\s|[.-])*)([0-9]{2})((\s|[.-])*)([0-9]{4})((\s|[.-])*)$/);
      if (panthernum) {
        return { field: 'Panther #', value: panthernum[3] + panthernum[6] + panthernum[9] };
      } else {
        return { field: 'error', value: 'Invalid Query String "' + queryString + '"' };
      }
    }
  }
}
